import React from 'react'
import IconStripeMedien from '~/images/icon-stripe-medien.svg'

const Underline = ({ text }) => {
  return (
    <div style={{ display: 'inline-block', position: 'relative' }}>
      {text}
      <img
        src={IconStripeMedien}
        alt=''
        style={{
          position: 'absolute',
          zIndex: '-1',
          left: '-10%',
          bottom: '4px',
          width: '120%'
        }}
      />
    </div>
  )
}
export default Underline
