import React from 'react'
import SwiperCore, { Navigation } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import IconMediaArrow from '~/images/icon-arrow-media.svg'
import IconYoutube from '~/images/icon-youtube.svg'
import IconPlay from '~/images/icon-play.svg'
import './mediaslider.scss'
import { FadeInRight } from '~/components/00-global/Animation/Animation'

SwiperCore.use([Navigation])
const MediaSlider = ({ handleShow }) => {
  const {
    imageVideoCowboy,
    imageVideoTopsecret,
    imageVideoCaptor,
    imageVideoMenspresent
  } = useStaticQuery(graphql`
    query {
      imageVideoTopsecret: file(
        relativePath: { eq: "image-media-secret.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 435) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageVideoCaptor: file(relativePath: { eq: "image-video-captor.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 435) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageVideoMenspresent: file(
        relativePath: { eq: "image-video-menspresent.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 435) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageVideoCowboy: file(relativePath: { eq: "image-video-cowboy.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 435) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const slides = [
    {
      title: 'Ein echtes Männergeschenk',
      image: imageVideoMenspresent,
      url: 'https://www.youtube.com/watch?v=IzEmXPIc98Q'
    },
    {
      title: 'Biersafe Cowboy IV',
      image: imageVideoCowboy,
      url: 'https://www.youtube.com/watch?v=LagYm06shNo'
    },
    {
      title: 'Der Ultimative Flaschenöffner',
      image: imageVideoCaptor,
      url: 'https://www.youtube.com/watch?v=vTgalwTjvE0'
    },
    {
      title: 'Beer Outta Nowhere',
      image: imageVideoTopsecret,
      url: 'https://www.youtube.com/watch?v=gHc4ezvEYos'
    }
  ]

  return (
    <>
      <Swiper
        loop={true}
        spaceBetween={8}
        slidesPerView={2}
        centeredSlides={true}
        className='mediaslider'
        navigation={{
          nextEl: '.next',
          prevEl: '.prev'
        }}
        breakpoints={{
          //when window width is >= 768px
          1200: {
            slidesPerView: 3,
            spaceBetween: 10
          }
        }}
      >
        <div className='overlay-left' />
        <div className='overlay-right' />
        {slides.map((slide, index) => (
          <SwiperSlide
            key={slide.title}
            onClick={() => handleShow(slide.url)}
            className='mediaslide'
          >
            <FadeInRight>
              <div>
                <div className='image-container'>
                  <Img
                    className='slideimage '
                    fluid={slide.image.childImageSharp.fluid}
                  />
                  <div className='image-overlay'>
                    <img src={IconPlay} alt='' />
                  </div>
                  <img className='icon' src={IconYoutube} alt='' />
                </div>
                <div className='slide-description'>
                  <h3>{slide.title}</h3>
                </div>
              </div>
            </FadeInRight>
          </SwiperSlide>
        ))}
        <div className='button-container'>
          <button className='prev'>
            <img src={IconMediaArrow} alt='' />
          </button>
          <button className='next'>
            <img src={IconMediaArrow} alt='' />
          </button>
        </div>
      </Swiper>
    </>
  )
}
export default MediaSlider
