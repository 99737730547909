import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import Underline from '~/components/00-global/Underline/Underline'
import { Scroll } from '~/components/00-global/Animation/Animation'

import './story.scss'

const Story = () => {
  const intl = useIntl()
  const {
    imageStoryOne,
    imageStoryTwo,
    imageStoryThree,
    imageStoryFour,
    imageStoryFive
  } = useStaticQuery(graphql`
    query {
      imageStoryOne: file(relativePath: { eq: "image-story-one.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 270) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageStoryTwo: file(relativePath: { eq: "image-story-two.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 340) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageStoryThree: file(relativePath: { eq: "image-story-three.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 540) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageStoryFour: file(relativePath: { eq: "image-story-four.png" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageStoryFive: file(relativePath: { eq: "image-story-five.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 670) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <section id='story' className='story'>
      <Container>
        <Row className='row-first'>
          <Col md={6} className='images-one order-2 '>
            <div className='image-one'>
              <Img
                className='image'
                fluid={imageStoryOne.childImageSharp.fluid}
                alt={intl.formatMessage({ id: 'aboutUs.story.images.0' })}
              />
            </div>
            <div className='image-two'>
              <Img
                className='image'
                fluid={imageStoryTwo.childImageSharp.fluid}
                alt={intl.formatMessage({ id: 'aboutUs.story.images.1' })}
              />
            </div>
          </Col>
          <Col md={6} className='col-flex-center order-md-2'>
            <div>
              <Scroll>
                <h2 className='stripe-vertical title-to-text'>
                  <FormattedMessage id='aboutUs.story.title' />
                </h2>
              </Scroll>
              <Scroll>
                <p>
                  <FormattedMessage id='aboutUs.story.text.0' />
                </p>
              </Scroll>
            </div>
          </Col>
        </Row>
        <Row className='row-quality'>
          <Col md={6} className='col-flex-center order-2'>
            <Scroll>
              <p>
                <FormattedMessage id='aboutUs.story.text.1' />
              </p>
            </Scroll>
          </Col>
          <Col md={6} className='images-two order-md-2'>
            <div className='image-three'>
              <Img
                className='image radius'
                fluid={imageStoryThree.childImageSharp.fluid}
                alt={intl.formatMessage({ id: 'aboutUs.story.images.2' })}
              />
            </div>
          </Col>
        </Row>
        <Row className='row-biersafe'>
          <Col md={6} className='images-three'>
            <div className='image-four'>
              <Img
                fluid={imageStoryFour.childImageSharp.fluid}
                alt={intl.formatMessage({ id: 'aboutUs.story.images.3' })}
              />
            </div>
          </Col>
          <Col md={6} className='col-flex-center'>
            <Scroll>
              <p>
                <FormattedMessage id='aboutUs.story.text.2' />
              </p>
            </Scroll>
          </Col>
        </Row>
        <Row className='row-captor'>
          <Col md={6} className='col-flex-center order-2'>
            <Scroll>
              <p>
                <FormattedMessage id='aboutUs.story.text.3' />
              </p>
            </Scroll>
          </Col>
          <Col md={6} className='images-four col-flex-center order-md-2'>
            <div className='image-five'>
              <Img
                className='image radius-abs'
                fluid={imageStoryFive.childImageSharp.fluid}
                alt={intl.formatMessage({ id: 'aboutUs.story.images.4' })}
              />
            </div>
          </Col>
        </Row>
        <hr className='separator' />
      </Container>
    </section>
  )
}

export default Story
