import React, { useState, useEffect } from 'react'

import SEO from '~/components/00-global/util/seo'
import PageHeader from '~/components/00-global/PageHeader/PageHeader'
import Story from '~/components/05-ueber-uns/story/Story'
import Media from '~/components/05-ueber-uns/media/Media'
import TeamInfo from '~/components/05-ueber-uns/TeamInfo/TeamInfo'
import InstagramWidget from '~/components/05-ueber-uns/InstagramWidget/InstagramWidget'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import Modal from '../components/00-global/Modal/Modal'
import { Load } from '~/components/00-global/Animation/Animation'

const TeamPage = () => {
  const intl = useIntl()
  const { imageHeroTeam } = useStaticQuery(graphql`
    query {
      imageHeroTeam: file(relativePath: { eq: "image-hero-team.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = url => setShow({ state: true, url: url })
  useEffect(() => {
    const html = document.getElementsByTagName('html')[0]
    const gatsby = document.getElementById('___gatsby')
    if (show === false) {
      if (typeof document !== `undefined`) {
        html.classList.remove('no-scroll')
        gatsby.ontouchmove = e => {
          return true
        }
      }
    } else {
      if (typeof document !== `undefined`) {
        html.classList.add('no-scroll')
        gatsby.ontouchmove = e => {
          e.preventDefault()
          return false
        }
      }
    }
  }, [show])
  return (
    <>
      <Modal show={show.state} url={show.url} handleClose={handleClose} />
      <Load>
        <SEO
          title={intl.formatMessage({ id: 'aboutUs.header.title' })}
          description={intl.formatMessage({ id: 'aboutUs.header.subtitle' })}
          pathname={`/ueber-uns`}
        />
        <PageHeader
          title={intl.formatMessage({ id: 'aboutUs.header.title' })}
          text={intl.formatMessage({ id: 'aboutUs.header.subtitle' })}
          image={imageHeroTeam.childImageSharp.fluid}
          yPos='top'
          xPosMob='0%'
          alt={intl.formatMessage({ id: 'aboutUs.header.image' })}
        />
        <Story />
        <Media handleShow={handleShow} />
        <InstagramWidget />
        <TeamInfo />
      </Load>
    </>
  )
}

export default TeamPage
