import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import './media.scss'
import MediaSlider from './MediaSlider'
import IconZdf from '~/images/icon-zdf.svg'
import IconSat1 from '~/images/icon-sat1.svg'
import IconRtl from '~/images/icon-rtl.svg'
import IconHgtv from '~/images/icon-hgtv.svg'
import IconWdr from '~/images/icon-wdr.svg'

const Media = ({ handleShow }) => {
  const intl = useIntl()
  return (
    <section id='media'>
      <Container>
        <Row className='media-text'>
          <Col className='col-heading' md={6}>
            <h2 className='stripe-vertical '>
              <FormattedMessage id='aboutUs.media.title' />
            </h2>
            <div className='logos'>
              <img
                src={IconZdf}
                alt={intl.formatMessage({ id: 'home.knownfrom.logos.0' })}
              />
              <img
                src={IconWdr}
                alt={intl.formatMessage({ id: 'home.knownfrom.logos.1' })}
              />
              <img
                src={IconRtl}
                alt={intl.formatMessage({ id: 'home.knownfrom.logos.2' })}
              />
              <img
                src={IconHgtv}
                alt={intl.formatMessage({ id: 'home.knownfrom.logos.3' })}
              />
              <img
                src={IconSat1}
                alt={intl.formatMessage({ id: 'home.knownfrom.logos.4' })}
              />
            </div>
          </Col>
          <Col md={6}>
            <p>
              <FormattedMessage id='aboutUs.media.text' />
            </p>
          </Col>
        </Row>
        <Row>
          <MediaSlider handleShow={handleShow} />
        </Row>
      </Container>
    </section>
  )
}

export default Media
