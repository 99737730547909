import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import {
  useIntl,
  FormattedMessage,
  FormattedHTMLMessage
} from 'gatsby-plugin-intl'
import './teamInfo.scss'
import IconStripeTeam from '~/images/icon-stripe-team.svg'
import { Scroll } from '~/components/00-global/Animation/Animation'

const TeamInfo = () => {
  const intl = useIntl()
  const { imageTeam } = useStaticQuery(graphql`
    query {
      imageTeam: file(relativePath: { eq: "image-team.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 670) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <section id='teaminfo' className='section-margin'>
      <Container>
        <Row>
          <Col md={6} className='col-text'>
            <Scroll>
              <h2 className='stripe-vertical title-to-text'>
                <FormattedMessage id='aboutUs.team.title' />
              </h2>
            </Scroll>
            <Scroll>
              <FormattedHTMLMessage id='aboutUs.team.text' />
            </Scroll>
          </Col>
          <Col md={6}>
            <div className='contentContainer'>
              <Img
                className='image'
                fluid={imageTeam.childImageSharp.fluid}
                alt={intl.formatMessage({ id: 'aboutUs.team.image' })}
              />
              <a href='mailto:info@biersafe.de'>
                <div className='email'>
                  <span>info@biersafe.de</span>
                  <img src={IconStripeTeam} alt='' />
                </div>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default TeamInfo
