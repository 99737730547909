import React from 'react'
import { ElfsightWidget } from 'react-elfsight-widget'
import { Container, Row, Col } from 'react-bootstrap'
import { Scroll } from '~/components/00-global/Animation/Animation'
import { FormattedMessage } from 'gatsby-plugin-intl'
import './InstagramWidget.scss'

const InstagramWidget = () => {
  return (
    <section id='instagram-widget'>
      <Container>
        <Row>
          <Col md={6} className='col-text'>
            <Scroll>
              <h2 className='stripe-vertical title-to-text'>
                <FormattedMessage id='aboutUs.instagram.title' />
              </h2>
            </Scroll>
          </Col>
        </Row>
        <ElfsightWidget widgetID='fc45ff51-19a6-4818-b668-e0193c89badd' />
      </Container>
    </section>
  )
}

export default InstagramWidget
