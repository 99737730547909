import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import './modal.scss'
import IconModalClose from '~/images/icon-modal-close.svg'
const MediaModal = ({ show, url, handleClose }) => {
  const [loading, setLoading] = useState(true)

  return (
    <Modal
      className='mediamodal'
      show={show}
      onHide={handleClose}
      centered
      size='lg'
      dialogClassName='modalvideo'
    >
      <Modal.Body>
        <div className='content-wrapper'>
          <div className={`video-wrapper ${loading ? 'loading' : 'loaded'}`}>
            <ReactPlayer
              className={`react-player`}
              url={url}
              width='100%'
              height='100%'
              controls
              onReady={() => {
                setLoading(false)
              }}
            />
          </div>
          <div className='iconclose' onClick={handleClose}>
            <img
              src={IconModalClose}
              alt=''
              className={`${loading ? 'loading' : 'loaded'}`}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MediaModal
